interface TimezoneOffset {
  offset: string;
  identifier: string;
}
const timezoneOffsets: TimezoneOffset[] = [
  { offset: "(UTC-12:00) International Date Line West", identifier: "Etc/GMT+12" },
  { offset: "(UTC-11:00) Coordinated Universal Time-11", identifier: "Etc/GMT+11" },
  { offset: "(UTC-10:00) Aleutian Islands", identifier: "America/Adak" },
  { offset: "(UTC-10:00) Hawaii", identifier: "Pacific/Honolulu" },
  { offset: "(UTC-09:30) Marquesas Islands", identifier: "Pacific/Marquesas" },
  { offset: "(UTC-09:00) Alaska", identifier: "America/Anchorage" },
  { offset: "(UTC-09:00) Coordinated Universal Time-09", identifier: "Etc/GMT+9" },
  { offset: "(UTC-08:00) Baja California", identifier: "America/Tijuana" },
  { offset: "(UTC-08:00) Coordinated Universal Time-08", identifier: "Etc/GMT+8" },
  { offset: "(UTC-08:00) Pacific Time (US & Canada)", identifier: "America/Los_Angeles" },
  { offset: "(UTC-07:00) Arizona", identifier: "America/Denver" },
  { offset: "(UTC-07:00) La Paz, Mazatlan", identifier: "America/Mazatlan" },
  { offset: "(UTC-07:00) Mountain Time (US & Canada)", identifier: "America/Denver" },
  { offset: "(UTC-07:00) Yukon", identifier: "America/Yukon" },
  { offset: "(UTC-06:00) Central America", identifier: "America/Guatemala" },
  { offset: "(UTC-06:00) Central Time (US & Canada)", identifier: "America/Chicago" },
  { offset: "(UTC-06:00) Easter Island", identifier: "Pacific/Easter" },
  { offset: "(UTC-06:00) Guadalajara, Mexico City, Monterrey", identifier: "America/Mexico_City" },
  { offset: "(UTC-06:00) Saskatchewan", identifier: "America/Regina" },
  { offset: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco", identifier: "America/Bogota" },
  { offset: "(UTC-05:00) Chetumal", identifier: "America/Cancun" },
  { offset: "(UTC-05:00) Eastern Time (US & Canada)", identifier: "America/New_York" },
  { offset: "(UTC-05:00) Haiti", identifier: "America/Port-au-Prince" },
  { offset: "(UTC-05:00) Havana", identifier: "America/Havana" },
  { offset: "(UTC-05:00) Indiana (East)", identifier: "America/Indianapolis" },
  { offset: "(UTC-05:00) Turks and Caicos", identifier: "America/Grand_Turk" },
  { offset: "(UTC-04:00) Asuncion", identifier: "America/Asuncion" },
  { offset: "(UTC-04:00) Atlantic Time (Canada)", identifier: "America/Halifax" },
  { offset: "(UTC-04:00) Caracas", identifier: "America/Caracas" },
  { offset: "(UTC-04:00) Cuiaba", identifier: "America/Cuiaba" },
  { offset: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan", identifier: "America/San_Juan" },
  { offset: "(UTC-04:00) Santiago", identifier: "America/Santiago" },
  { offset: "(UTC-03:30) Newfoundland", identifier: "America/St_Johns" },
  { offset: "(UTC-03:00) Araguaina", identifier: "America/Araguaina" },
  { offset: "(UTC-03:00) Brasilia", identifier: "America/Sao_Paulo" },
  { offset: "(UTC-03:00) Cayenne, Fortaleza", identifier: "America/Cayenne" },
  { offset: "(UTC-03:00) City of Buenos Aires", identifier: "America/Argentina/Buenos_Aires" },
  { offset: "(UTC-03:00) Montevideo", identifier: "America/Montevideo" },
  { offset: "(UTC-03:00) Punta Arenas", identifier: "America/Punta_Arenas" },
  { offset: "(UTC-03:00) Saint Pierre and Miquelon", identifier: "America/St_Pierre" },
  { offset: "(UTC-03:00) Salvador", identifier: "America/Bahia" },
  { offset: "(UTC-02:00) Coordinated Universal Time-02", identifier: "Etc/GMT+2" },
  { offset: "(UTC-02:00) Greenland", identifier: "America/Nuuk" },
  { offset: "(UTC-01:00) Azores", identifier: "Atlantic/Azores" },
  { offset: "(UTC-01:00) Cabo Verde Is.", identifier: "Atlantic/Cape_Verde" },
  { offset: "(UTC) Coordinated Universal Time", identifier: "Etc/UTC" },
  { offset: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London", identifier: "Europe/Dublin" },
  { offset: "(UTC+00:00) Monrovia, Reykjavik", identifier: "Africa/Monrovia" },
  { offset: "(UTC+00:00) Sao Tome", identifier: "Africa/Sao_Tome" },
  { offset: "(UTC+01:00) Casablanca", identifier: "Africa/Casablanca" },
  { offset: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", identifier: "Europe/Amsterdam" },
  { offset: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", identifier: "Europe/Belgrade" },
  { offset: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris", identifier: "Europe/Brussels" },
  { offset: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb", identifier: "Europe/Sarajevo" },
  { offset: "(UTC+01:00) West Central Africa", identifier: "Africa/Lagos" },
  { offset: "(UTC+02:00) Athens, Bucharest", identifier: "Europe/Athens" },
  { offset: "(UTC+02:00) Beirut", identifier: "Asia/Beirut" },
  { offset: "(UTC+02:00) Cairo", identifier: "Africa/Cairo" },
  { offset: "(UTC+02:00) Chisinau", identifier: "Europe/Chisinau" },
  { offset: "(UTC+02:00) Gaza, Hebron", identifier: "Asia/Gaza" },
  { offset: "(UTC+02:00) Harare, Pretoria", identifier: "Africa/Johannesburg" },
  { offset: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", identifier: "Europe/Helsinki" },
  { offset: "(UTC+02:00) Jerusalem", identifier: "Asia/Jerusalem" },
  { offset: "(UTC+02:00) Juba", identifier: "Africa/Juba" },
  { offset: "(UTC+02:00) Kaliningrad", identifier: "Europe/Kaliningrad" },
  { offset: "(UTC+02:00) Khartoum", identifier: "Africa/Khartoum" },
  { offset: "(UTC+02:00) Tripoli", identifier: "Africa/Tripoli" },
  { offset: "(UTC+02:00) Windhoek", identifier: "Africa/Windhoek" },
  { offset: "(UTC+03:00) Amman", identifier: "Asia/Amman" },
  { offset: "(UTC+03:00) Baghdad", identifier: "Asia/Baghdad" },
  { offset: "(UTC+03:00) Damascus", identifier: "Asia/Damascus" },
  { offset: "(UTC+03:00) Istanbul", identifier: "Europe/Istanbul" },
  { offset: "(UTC+03:00) Kuwait, Riyadh", identifier: "Asia/Kuwait" },
  { offset: "(UTC+03:00) Minsk", identifier: "Europe/Minsk" },
  { offset: "(UTC+03:00) Moscow, St. Petersburg", identifier: "Europe/Moscow" },
  { offset: "(UTC+03:00) Nairobi", identifier: "Africa/Nairobi" },
  { offset: "(UTC+03:00) Volgograd", identifier: "Europe/Volgograd" },
  { offset: "(UTC+03:30) Tehran", identifier: "Asia/Tehran" },
  { offset: "(UTC+04:00) Abu Dhabi, Muscat", identifier: "Asia/Dubai" },
  { offset: "(UTC+04:00) Astrakhan, Ulyanovsk", identifier: "Europe/Astrakhan" },
  { offset: "(UTC+04:00) Baku", identifier: "Asia/Baku" },
  { offset: "(UTC+04:00) Izhevsk, Samara", identifier: "Europe/Samara" },
  { offset: "(UTC+04:00) Port Louis", identifier: "Indian/Mauritius" },
  { offset: "(UTC+04:00) Saratov", identifier: "Europe/Saratov" },
  { offset: "(UTC+04:00) Tbilisi", identifier: "Asia/Tbilisi" },
  { offset: "(UTC+04:00) Yerevan", identifier: "Asia/Yerevan" },
  { offset: "(UTC+04:30) Kabul", identifier: "Asia/Kabul" },
  { offset: "(UTC+05:00) Ashgabat, Tashkent", identifier: "Asia/Ashgabat" },
  { offset: "(UTC+05:00) Ekaterinburg", identifier: "Asia/Yekaterinburg" },
  { offset: "(UTC+05:00) Islamabad, Karachi", identifier: "Asia/Karachi" },
  { offset: "(UTC+05:00) Qyzylorda", identifier: "Asia/Qyzylorda" },
  { offset: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", identifier: "Asia/Kolkata" },
  { offset: "(UTC+05:30) Sri Jayawardenepura", identifier: "Asia/Colombo" },
  { offset: "(UTC+05:45) Kathmandu", identifier: "Asia/Kathmandu" },
  { offset: "(UTC+06:00) Astana", identifier: "Asia/Almaty" },
  { offset: "(UTC+06:00) Dhaka", identifier: "Asia/Dhaka" },
  { offset: "(UTC+06:00) Omsk", identifier: "Asia/Omsk" },
  { offset: "(UTC+06:30) Yangon (Rangoon)", identifier: "Asia/Yangon" },
  { offset: "(UTC+07:00) Bangkok, Hanoi, Jakarta", identifier: "Asia/Bangkok" },
  { offset: "(UTC+07:00) Barnaul, Gorno-Altaysk", identifier: "Asia/Barnaul" },
  { offset: "(UTC+07:00) Hovd", identifier: "Asia/Hovd" },
  { offset: "(UTC+07:00) Krasnoyarsk", identifier: "Asia/Krasnoyarsk" },
  { offset: "(UTC+07:00) Novosibirsk", identifier: "Asia/Novosibirsk" },
  { offset: "(UTC+07:00) Tomsk", identifier: "Asia/Tomsk" },
  { offset: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", identifier: "Asia/Shanghai" },
  { offset: "(UTC+08:00) Irkutsk", identifier: "Asia/Irkutsk" },
  { offset: "(UTC+08:00) Kuala Lumpur, Singapore", identifier: "Asia/Singapore" },
  { offset: "(UTC+08:00) Perth", identifier: "Australia/Perth" },
  { offset: "(UTC+08:00) Taipei", identifier: "Asia/Taipei" },
  { offset: "(UTC+08:00) Ulaanbaatar", identifier: "Asia/Ulaanbaatar" },
  { offset: "(UTC+08:45) Eucla", identifier: "Australia/Eucla" },
  { offset: "(UTC+09:00) Chita", identifier: "Asia/Chita" },
  { offset: "(UTC+09:00) Osaka, Sapporo, Tokyo", identifier: "Asia/Tokyo" },
  { offset: "(UTC+09:00) Pyongyang", identifier: "Asia/Pyongyang" },
  { offset: "(UTC+09:00) Seoul", identifier: "Asia/Seoul" },
  { offset: "(UTC+09:00) Yakutsk", identifier: "Asia/Yakutsk" },
  { offset: "(UTC+09:30) Adelaide", identifier: "Australia/Adelaide" },
  { offset: "(UTC+09:30) Darwin", identifier: "Australia/Darwin" },
  { offset: "(UTC+10:00) Brisbane", identifier: "Australia/Brisbane" },
  { offset: "(UTC+10:00) Canberra, Melbourne, Sydney", identifier: "Australia/Sydney" },
  { offset: "(UTC+10:00) Guam, Port Moresby", identifier: "Pacific/Guam" },
  { offset: "(UTC+10:00) Hobart", identifier: "Australia/Hobart" },
  { offset: "(UTC+10:00) Vladivostok", identifier: "Asia/Vladivostok" },
  { offset: "(UTC+10:30) Lord Howe Island", identifier: "Australia/Lord_Howe" },
  { offset: "(UTC+11:00) Bougainville Island", identifier: "Pacific/Bougainville" },
  { offset: "(UTC+11:00) Chokurdakh", identifier: "Asia/Khandyga" },
  { offset: "(UTC+11:00) Magadan", identifier: "Asia/Magadan" },
  { offset: "(UTC+11:00) Norfolk Island", identifier: "Pacific/Norfolk" },
  { offset: "(UTC+11:00) Sakhalin", identifier: "Asia/Sakhalin" },
  { offset: "(UTC+11:00) Solomon Is., New Caledonia", identifier: "Pacific/Guadalcanal" },
  { offset: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky", identifier: "Asia/Anadyr" },
  { offset: "(UTC+12:00) Auckland, Wellington", identifier: "Pacific/Auckland" },
  { offset: "(UTC+12:00) Coordinated Universal Time+12", identifier: "Etc/GMT-12" },
  { offset: "(UTC+12:00) Fiji", identifier: "Pacific/Fiji" },
  { offset: "(UTC+12:45) Chatham Islands", identifier: "Pacific/Chatham" },
  { offset: "(UTC+13:00) Coordinated Universal Time+13", identifier: "Etc/GMT-13" },
  { offset: "(UTC+13:00) Nuku'alofa", identifier: "Pacific/Tongatapu" },
  { offset: "(UTC+13:00) Samoa", identifier: "Pacific/Apia" },
  { offset: "(UTC+14:00) Kiritimati Island", identifier: "Pacific/Kiritimati" },
];
export default timezoneOffsets;
