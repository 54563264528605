import { cloneDeep } from "lodash-es";
import { useAddPositionToStep } from "@/flow/application/useAddPositionToStep";

export function getMetaoutputListForAllStep(stepList) {
  const newSteps = useAddPositionToStep(cloneDeep(stepList));
  const metaoutputList = [];
  function getList(list) {
    list.forEach((step, index) => {
      step.ix = index;
      if (step.metaoutput) {
        const values: any = Object.values(step?.actions || []);
        metaoutputList.push({
          ...step,
          metaoutput: step?.metaoutput,
          output: step?.output,
          ix: step?.ix,
          // eslint-disable-next-line radix
          index: step?.position?.x,
          connection: step.name,
          action: values ? values?.[0]?.description : "",
          position: step?.position,
          conditionPosition: step?.conditionPosition,
        });
      }

      if (step.steps) {
        getList(step.steps);
      }
      if (step.routes) {
        getList(step.routes);
      }
    });
  }

  getList(newSteps);

  return metaoutputList;
}
