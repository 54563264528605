import { Client } from "webstomp-client";
import { isRunning } from "@/flow/services/flow.service";
import WebsocketUrlProvider from "@/core/services/websocketUrl.provider";

export function useActivity() {
  async function checkIsRunActivityFromSocket(stompClient:Client, activityId):Promise<boolean> {
    return new Promise((resolve) => {
      stompClient.subscribe(WebsocketUrlProvider.ActivityStatus(activityId),
        (val) => {
          if (!isRunning(val.body)) {
            resolve(false);
          }
        });
    });
  }

  return {
    checkIsRunActivityFromSocket,
  };
}
