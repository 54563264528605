export function isAnyStepHasNotConnectorProp(list) {
  // eslint-disable-next-line consistent-return
  function findConnector(findingList) {
    for (let i = 0; i < findingList.length; i++) {
      const step = findingList[i];

      if (step.name !== "router" && !step.connection && (step?.steps?.length === 0 || !step?.steps)) {
        return false;
      }

      if (Array.isArray(step.routes) && step?.routes?.length > 0) {
        if (!findConnector(step.routes)) {
          return false;
        }
      }
      if (Array.isArray(step.steps) && step?.steps?.length > 0) {
        if (!findConnector(step.steps)) {
          return false;
        }
      }
    }
    return true;
  }

  return findConnector(list);
}
