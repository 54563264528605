import LeaderLine from "leader-line";
import { ref } from "vue";
import { setWrapperPosition } from "@/flow/services/flow.service";

const loopLineList = ref([]);
function createLineWithParams(lineParams) {
  const start = document.getElementById(`step-${lineParams.startId}`);
  const end = document.getElementById(`step-${lineParams.endId}`);
  if (start && end) {
    const leaderLine = new LeaderLine(LeaderLine.pointAnchor(start, {
      x: end.clientLeft + 120,
      y: end.clientTop + lineParams.lineStart,
    }),
    LeaderLine.pointAnchor(end, {
      x: end.clientLeft,
      y: end.clientTop + lineParams.lineStart,
    }), {
      color: lineParams.color,
      startPlugColor: lineParams.endPlugColor,
      endPlugColor: lineParams.endPlugColor,
      size: 4,
      endPlug: "behind",
      startSocket: "right",
      endSocket: "left",
      gradient: lineParams.gradient,
      dash: lineParams.dash,
    });
    loopLineList.value.push(leaderLine);
  }
}

function findAllLoopsInsideFlow(stepList) {
  const pairs = [];

  function processSteps(steps) {
    const startIndices = [];
    const endIndices = [];

    steps.forEach((step, index) => {
      if (checkForLoopAndEndByType("LOOP", step)) {
        startIndices.push(index);
      } else if (checkForLoopAndEndByType("END", step)) {
        endIndices.push(index);
      } else if (Array.isArray(step.routes) && step?.routes?.length > 0) {
        step.routes.forEach((route) => {
          processSteps(route.steps);
        });
      }
    });
    const isOrder = checkArrayOrder(startIndices, endIndices);
    startIndices.forEach((startIndex, index) => {
      // eslint-disable-next-line no-shadow
      let endIndex = endIndices.slice().reverse().find((endI) => endI > startIndex);
      if (isOrder) {
        endIndex = endIndices.find((endI) => endI > startIndex);
      }

      if (endIndex !== undefined) {
        pairs.push({
          startId: steps[startIndex].uniqueId,
          endId: steps[endIndex].uniqueId,
          startIndex,
          endIndex,
          lineIndex: index,
        });
        endIndices.splice(endIndices.indexOf(endIndex), 1);
      }
    });
  }

  processSteps(stepList);
  return pairs;
}

function checkForLoopAndEndByType(type, step) {
  return (step.translations || []).some((item) => item.expressionType === type);
}

function checkArrayOrder(startIndices, endIndices) {
  // eslint-disable-next-line max-len
  return mergeArraysByIndex(0, startIndices, endIndices).every((value, index, array) => index === 0 || value >= array[index - 1]);
}

function mergeArraysByIndex(indexOrder, ...arrays) {
  const mergedArray = [];
  const maxLength = Math.max(...arrays.map((array) => array.length));

  for (let i = 0; i < maxLength; i++) {
    for (let j = 0; j < arrays.length; j++) {
      if (i < arrays[j].length) {
        mergedArray.push(arrays[j][i]);
      }
    }
  }

  return mergedArray;
}

export function useLoopLines(steps, wrapperElement) {
  function createOrUpdateLines() {
    const loopList = findAllLoopsInsideFlow(steps);
    clearLoopLines(wrapperElement);
    const lineStart = 96;
    const bottomStart = 92;
    const topStart = 68;
    loopList.forEach((item, index) => {
      createLineWithParams({
        startId: item.startId,
        endId: item.endId,
        lineStart: lineStart + (item.lineIndex * 12),
        bottomStart,
        topStart,
        clientWidth: 250,
        gradient: false,
        color: "#F3E228FF",
        startPlugColor: undefined,
        endPlugColor: undefined,
        dash: {
          len: 4,
          gap: 8,
        },
      });
    });
    const lineElements = document.querySelectorAll(".leader-line");
    lineElements.forEach((lineElement) => {
      if (lineElement.querySelector("use").getAttribute("style").includes("stroke-width: 4px")) {
        wrapperElement.appendChild(lineElement);
      }
    });
    setWrapperPosition(wrapperElement);
  }

  return {
    createOrUpdateLines,
    lineCount: loopLineList.value.length,
  };
}

function clearLoopLines(wrapperElement) {
  if (wrapperElement) {
    wrapperElement.innerHTML = "";
    loopLineList.value = [];
  }
}
